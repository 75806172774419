<template>
  <div
    v-loading="loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    id="mapContent"></div>
</template>
<script>
import Tool from '@/assets/js/utils'
import config from '@/config'
import { SHOWDEVICEIMG, SETSOURCEICON } from '@/assets/js/filters'
export default {
  props: {
    mapQueryObj: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      mapObj: null,
      bMapObj: null,
      typeFlag: 'source',
      selectSId: '',
      markers: {},
      bounds: null,
      points: [],
      infowindows: {},
      infowindowsFlag: {},
      sourceLiveRList: {}, // The live receiver list sorted by the source peerId.
      sourceListClickable: {}, // 记录Source是否可点击状态
      sourceLiveRKey: [], // 记录R的key
      polylineList: {}, // The polyline list sorted by the source peerId.
      loading: false,
      rData: [], // 接口数据
      condition: '',
      mapLoaded: 'false',
      timeOut: null,
      lang: 'en',
      initTimers: null,
      initBaiduTimers: null,
      pointArr: [],
      mapSourceLoaded: false
    }
  },
  mounted () {
    this.loading = true
    this.lang = window.localStorage.getItem('lang')

    Tool.loadMapJs(this.lang, () => {
      this.loading = false
      if (this.lang == 'zh') {
        this.mapSourceLoaded = true
        this.initMapBaidu()// 中文情况下加载百度地图
      } else {
        this.mapSourceLoaded = true
        this.initMap()
      }
      this.mapTimer() // 按照选定时间修改刷新频率
    }, 'markercluster')
  },
  watch: {
    mapQueryObj: function (obj) {
      if (obj.queryFlag && this.mapSourceLoaded) {
        this.queryMap()
      }
      this.mapTimer()
    }
  },
  methods: {
    mapTimer () {
      if (this.timeOut) clearInterval(this.timeOut)
      this.timeOut = setInterval(() => {
        if (this.mapSourceLoaded) this.queryMap()
      }, +this.mapQueryObj.freshTime * 1000)
    },
    // google地图初始化
    initMap () {
      if (this.mapObj == null) {
        const latlng = new google.maps.LatLng(34.0887, -118.3278)
        try {
          this.mapObj = new google.maps.Map(document.getElementById('mapContent'), {
            zoom: 3,
            center: latlng,
            disableDefaultUI: false,
            minZoom: 2,
            maxZoom: 22,
            zoomControl: true
          })
          this.bounds = new google.maps.LatLngBounds()
          // 控制zoom图标的默认样式
          this.zoomChange()
        } catch (error) {
        }
      }
      this.markers = {}
      this.initTimers = setInterval(() => {
        if (this.mapQueryObj && this.mapQueryObj != 'undefined') {
          clearInterval(this.initTimers)
          this.queryMap('init') // 进入界面初始化地图数据
        }
      }, 100)
    },
    initMapBaidu () {
      const maxZoom = 20 // 最大层级
      window.BMAP_NORMAL_MAP.m.u4 = window.BMAP_NORMAL_MAP.m.qc = window.BMAP_NORMAL_MAP.m.maxZoom = window.BMAP_PERSPECTIVE_MAP.m.u4 = window.BMAP_PERSPECTIVE_MAP.m.qc = window.BMAP_PERSPECTIVE_MAP.m.maxZoom = window.BMAP_SATELLITE_MAP.m.u4 = window.BMAP_SATELLITE_MAP.m.qc = window.BMAP_SATELLITE_MAP.m.maxZoom = window.BMAP_HYBRID_MAP.m.u4 = window.BMAP_HYBRID_MAP.m.qc = window.BMAP_HYBRID_MAP.m.maxZoom = maxZoom

      // 百度地图API功能
      this.bMapObj = new BMap.Map('mapContent', { minZoom: 3 }) // 创建Map实例

      this.bMapObj.centerAndZoom(new BMap.Point(116.404, 39.915), 3) // 初始化地图,设置中心点坐标和地图级别
      // 添加地图类型控件
      this.bMapObj.addControl(new BMap.MapTypeControl({
        mapTypes: [
          BMAP_NORMAL_MAP,
          BMAP_HYBRID_MAP
        ]
      }))
      const topRightNavigation = new BMap.NavigationControl({ anchor: 'BMAP_ANCHOR_BOTTOM_RIGHT', type: 'BMAP_NAVIGATION_CONTROL_ZOOM' })
      this.bMapObj.addControl(topRightNavigation)
      this.bMapObj.setCurrentCity('北京') // 设置地图显示的城市 此项是必须设置的
      this.bMapObj.enableScrollWheelZoom(true) // 开启鼠标滚轮缩放
      this.markers = {}
      this.initBaiduTimers = setInterval(() => {
        if (this.mapQueryObj && this.mapQueryObj != 'undefined') {
          clearInterval(this.initBaiduTimers)
          this.queryMap() // 进入界面初始化地图数据
        }
      }, 10)
    },
    // 加载地图数据
    queryMap (str) {
      const url = this.$route.path.includes('nhm') ? '/ccp/tvucc-user/userDevice/listSourcePositionForHome' : '/ccp/tvucc-user/userDevice/listSourcePosition'
      if (this.lang == 'zh') {
        this.mapQueryObj.query.mapType = 'baidu'
        this.axios.post(url, this.mapQueryObj.query, { contentType: 'application/json;charset=UTF-8' }).then(res => {
          if (res.data.errorCode == '0x0' && res.data.errorInfo == 'Success') {
            const resultData = res.data.result
            let noPosition = 0; let livingPosition = 0; let onlinePosition = 0; let offlinePosition = 0
            resultData.forEach(v => {
              if (v.position == '0,0' || v.position == null || v.position == '0.006,0.0065') {
                noPosition += 1
              } else {
                if (v.status == '2') {
                  livingPosition += 1
                } else if (v.status == '0') {
                  offlinePosition += 1
                } else {
                  onlinePosition += 1
                }
              }
            })
            const totalPosition = resultData.length
            this.rData = resultData
            const obj = {
              livingPosition,
              onlinePosition,
              offlinePosition,
              totalPosition,
              noPosition
            }
            this.$emit('mapNumChange', obj)
            if (this.lang == 'zh') {
              this.filterBMapData(str)
            } else {
              this.filterMapData(str)
            }
          }
        }).catch(() => {})
      } else {
        this.axios.post(url, this.mapQueryObj.query, { contentType: 'application/json;charset=UTF-8' }).then(res => {
          if (res.data.errorCode == '0x0' && res.data.errorInfo == 'Success') {
            const resultData = res.data.result
            let noPosition = 0; let livingPosition = 0; let onlinePosition = 0; let offlinePosition = 0
            resultData.forEach(v => {
              if (v.position == '0,0' || v.position == null || v.position == '0.006,0.0065') {
                noPosition += 1
              } else {
                if (v.status == '2') {
                  livingPosition += 1
                } else if (v.status == '0') {
                  offlinePosition += 1
                } else {
                  onlinePosition += 1
                }
              }
            })
            const totalPosition = resultData.length
            this.rData = resultData
            const obj = {
              livingPosition,
              onlinePosition,
              offlinePosition,
              totalPosition,
              noPosition
            }
            this.$emit('mapNumChange', obj)
            if (this.lang == 'zh') {
              this.filterBMapData(str)
            } else {
              this.filterMapData(str)
            }
          }
        }).catch(() => {})
      }
    },
    filterBMapData (str) {
      if (this.bMapObj != null) {
        if (this.rData) {
          if (this.$route.path.includes('nhm')) {
            this.points = []
          } else {
            this.bounds = new BMap.Bounds()
          }
          // 判断Source数据是否还存在 若存在，则数据状态是否有更改
          const newMarkerKey = this.getValueByKey(this.rData, 'peerId')
          Object.keys(this.markers).map(k => {
            if (!newMarkerKey.includes(k) && !this.sourceLiveRKey.includes(k)) {
              this.bMapObj.removeEventListener('click', this.markers[k])
              this.clearRMarkerAndPolyLine(k)// 清空当前Source的line和r的marker
              if (this.infowindows[k]) {
                delete this.infowindows[k]
                delete this.infowindowsFlag[k]
              }
              this.clearMarker(k) // 清空source 的 marker
            } else {
              if (this.$route.path.includes('nhm')) {
                this.points.push(this.markers[k].getPosition())
              } else {
                this.bounds.extend(this.markers[k].getPosition())
              }
            }
          })
          // 每次循环更新的是source的数据，所以每次更新都需要将typeFlag置为source
          this.typeFlag = 'source'
          this.updateBaiDuMapMarker(this.rData, str)
        }
      }
    },
    filterMapData (str) {
      if (this.mapObj != null) {
        if (this.rData) {
          this.bounds = new google.maps.LatLngBounds()
          // 判断Source数据是否还存在 若存在，则数据状态是否有更改
          const newMarkerKey = this.getValueByKey(this.rData, 'peerId')
          Object.keys(this.markers).map(k => {
            if (!newMarkerKey.includes(k) && !this.sourceLiveRKey.includes(k)) {
              google.maps.event.clearListeners(this.markers[k])
              this.clearRMarkerAndPolyLine(k)// 清空当前Source的line和r的marker
              if (this.infowindows[k]) {
                delete this.infowindows[k]
                delete this.infowindowsFlag[k]
              }
              this.clearMarker(k) // 清空source 的 marker
            } else {
              this.bounds.extend(this.markers[k].position)
            }
          })
          // 每次循环更新的是source的数据，所以每次更新都需要将typeFlag置为source
          this.typeFlag = 'source'
          this.updateMapMarker(this.rData, str)
        }
      }
    },
    // update map data
    updateMapMarker (data, str) {
      if (this.markerCluster && this.typeFlag == 'source') this.markerCluster.clearMarkers()
      if (data.length == 0) {
        this.mapObj.setCenter(new google.maps.LatLng(34.0887, -118.3278))
        this.mapObj.setZoom(3)
      } else {
        data.map(item => {
          const pid = item.peerId
          const position = item.position
          const status = item.status
          // const name = item.name || ''
          if (position == null || position == '0,0') return // 没有地理位置不显示
          const marketP = position && position.split(',')
          const lat = Number(marketP && marketP[0])
          const lng = Number(marketP && marketP[1])
          // 数据整合
          const iconurl = this.getIconUrl(pid, status)
          const imagePath = SHOWDEVICEIMG(item)
          const latlng = new google.maps.LatLng(lat, lng)
          const opt = {
            position: latlng,
            map: this.mapObj,
            icon: iconurl
          }
          this.addMarker(pid, opt, imagePath, item)
          this.updateLineOrMarker(pid)
        })
        if (str && str == 'init') {
          this.mapObj.fitBounds(this.bounds)
        }
      }
    },
    updateBaiDuMapMarker (data, str) {
      if (this.markerCluster && this.typeFlag == 'source') this.markerCluster.clearMarkers()
      if (data.length == 0) {
        var point = new BMap.Point(34.0887, -118.3278) // 创建点坐标
        this.bMapObj.centerAndZoom(point, 3)
      } else {
        let points = []
        data.map((item, index) => {
          const pid = item.peerId
          const position = item.position
          const status = item.status
          if (position == null || position == '0.006,0.0065' || position == '0,0') return // 没有地理位置不显示
          const marketP = position && position.split(',')
          const lat = Number(marketP && marketP[0] || 0)
          const lng = Number(marketP && marketP[1] || 0)
          const latlng = new BMap.Point(lng, lat)
          points.push(latlng)
          const iconurl = this.getIconUrl(pid, status)
          const imagePath = SHOWDEVICEIMG(item)
          const opt = {
            position: latlng,
            map: this.mapObj,
            icon: iconurl
          }
          this.addBaiDuMarker(pid, opt, imagePath, item)
          this.updateLineOrMarker(pid)
        })
        if (str && str == 'init') {
          const viewPort = this.bMapObj.getViewport(points)
          this.bMapObj.centerAndZoom(viewPort.center, viewPort.zoom)
        }
      }
    },
    // add or update marker
    addBaiDuMarker (key, opt, imagePath, source) {
      let marker = this.markers[key]
      imagePath = imagePath || config.myResouceMapIcon.logoImg
      if (!marker || marker.length == 0) {
        const myIcon = new BMap.Icon(opt.icon, new BMap.Size(42, 40))
        marker = new BMap.Marker(opt.position, { icon: myIcon }) // 创建标注
        marker.source = source
        this.markers[key] = marker
        this.bMapObj.addOverlay(marker)
        this.addBaiDuMarkerEvent(key, imagePath, source, this.typeFlag)
      } else {
        marker.source = source
        this.markers[key] = marker
        marker.setIcon(new BMap.Icon(opt.icon, new BMap.Size(42, 40)))
        marker.setPosition(new BMap.Point(opt.position.lng, opt.position.lat))
        if (source.type != 'R' && this.markerCluster) {
          this.markerCluster.removeMarker(this.markers[key])
        }
      }
      this.points.push(marker.position)
      if (source.type != 'R') {
        if (this.markerCluster) {
          this.markerCluster.addMarkers([marker])
        } else {
          this.markerCluster = new BMapLib.MarkerClusterer(this.bMapObj, {
            markers: this.markers,
            minZoom: 3,
            maxZoom: 19,
            gridSize: 100,
            isAverangeCenter: true,
            minClusterSize: 8,
            styles: [{
              url: './markerbox/b1.png',
              size: new BMap.Size(53, 53)
            },
            {
              url: './markerbox/b2.png',
              size: new BMap.Size(56, 56)
            },
            {
              url: './markerbox/b3.png',
              size: new BMap.Size(66, 66)
            },
            {
              url: './markerbox/b4.png',
              size: new BMap.Size(78, 78)
            },
            {
              url: './markerbox/b5.png',
              size: new BMap.Size(90, 90)
            }]
          })
        }
      }
      return marker
    },
    // add or update marker
    addMarker (key, obj, imagePath, source) {
      let marker = this.markers[key]
      imagePath = imagePath || config.myResouceMapIcon.logoImg
      if (!marker || marker.length == 0) {
        marker = new google.maps.Marker(obj)
        marker.source = source
        this.markers[key] = marker
        this.addMarkerEvent(key, imagePath, source, this.typeFlag)
      } else {
        marker.source = source
        this.markers[key] = marker
        marker.setIcon(obj.icon)
        marker.setPosition(obj.position)
        if (source.type != 'R' && this.markerCluster) {
          this.markerCluster.removeMarker(this.markers[key])
        }
      }
      this.bounds.extend(marker.position)
      // showMarkersKey必须是数组
      if (source.type != 'R') {
        if (this.markerCluster) {
          this.markerCluster.addMarker(marker, false)
        } else {
          this.markerCluster = new MarkerClusterer(this.mapObj, this.markers, {
            minZoom: 2,
            maxZoom: 21,
            gridSize: 100,
            averageCenter: true,
            minimumClusterSize: 8,
            imagePath: './markerbox/b'
          })
        }
      }
      return marker
    },
    // 给marker添加点击事件
    addBaiDuMarkerEvent (key, imagePath, source, type) {
      let marker = this.markers[key]
      let timeT = marker.source.reportTime ? `${this.$options.filters.DATE(marker.source.reportTime, 'MM/dd/yyyy hh:mm:ss')}` : ''
      let iw = new BMap.InfoWindow(
        `<div class="infoContent" id="${key}" style="height: 250px;width:320px;">
          <img src="${imagePath}" onerror="this.src='${config.myResouceMapIcon.logoImg}'">
          <span class="iconfont deviceIcon">${SETSOURCEICON(marker.source, true)}</span>
          <div class="deviceInfo hasImg">
            <p class="name">${marker.source.name}&nbsp;&nbsp;<span style="color: #999" title="${timeT}">${timeT == '' ? '' : `(${timeT})`}</span></p>
            <p class="peerId" title="${marker.source.type == 'X' ? marker.source.peerId.toUpperCase() : ''}">${marker.source.peerId.toUpperCase()}</p>
          </div>
        </div>`
      )
      let message = new BMap.InfoWindow(
        `<div class="infoContent" style="height: 60px;width:fit-content;width: 320px;">
          <span class="iconfont deviceIcon">${SETSOURCEICON(marker.source, true)}</span>
          <div class="deviceInfo">
            <p class="name">${marker.source.name}&nbsp;&nbsp;<span style="color: #999">${timeT == '' ? '' : `(${timeT})`}</span></p>
            <p class="peerId">${marker.source.peerId.toUpperCase()}</p>
          </div>
        </div>`
      )
      marker.addEventListener('mouseover', (e) => {
        if (!this.infowindowsFlag[key]) {
          marker = this.markers[key]
          timeT = marker.source.reportTime ? `${this.$options.filters.DATE(marker.source.reportTime, 'MM/dd/yyyy hh:mm:ss')}` : ''
          message = new BMap.InfoWindow(
        `<div class="infoContent" style="height: 60px;width:fit-content;width: 320px;">
          <span class="iconfont deviceIcon">${SETSOURCEICON(marker.source, true)}</span>
          <div class="deviceInfo">
            <p class="name">${marker.source.name}&nbsp;&nbsp;<span style="color: #999">${timeT == '' ? '' : `(${timeT})`}</span></p>
            <p class="peerId">${marker.source.peerId.toUpperCase()}</p>
          </div>
        </div>`
          )
          marker.openInfoWindow(message)
        }
      })
      marker.addEventListener('mouseout', (e) => {
        message.close(this.mapObj, marker)
      })
      iw.addEventListener('close', (e) => {
        this.infowindowsFlag[key] = false
      }, marker)
      if (type != 'source') return
      this.infowindowsFlag[key] = false
      marker.addEventListener('click', (e) => {
        message.close(this.mapObj, marker)
        this.clearRMarkerAndPolyLine()
        this.sourceLiveRKey = []
        if (this.infowindowsFlag[key]) {
          this.closeInfoBox(key)
        } else {
          const flag = this.sourceListClickable[key]
          this.selectSId = key
          this.closeInfoBox(key)
          marker = this.markers[key]
          timeT = marker.source.reportTime ? `${this.$options.filters.DATE(marker.source.reportTime, 'MM/dd/yyyy hh:mm:ss')}` : ''
          imagePath = SHOWDEVICEIMG(marker.source)
          iw = new BMap.InfoWindow(
        `<div class="infoContent" id="${key}" style="height: 250px;width:320px;">
          <img src="${imagePath}" onerror="this.src='${config.myResouceMapIcon.logoImg}'">
          <span class="iconfont deviceIcon">${SETSOURCEICON(marker.source, true)}</span>
          <div class="deviceInfo hasImg">
            <p class="name">${marker.source.name}&nbsp;&nbsp;<span style="color: #999" title="${timeT}">${timeT == '' ? '' : `(${timeT})`}</span></p>
            <p class="peerId" title="${marker.source.type == 'X' ? marker.source.peerId.toUpperCase() : ''}">${marker.source.peerId.toUpperCase()}</p>
          </div>
        </div>`
          )
          this.infowindows[key] = iw
          this.markers[key].openInfoWindow(this.infowindows[key])
          this.infowindowsFlag[key] = true
          if (flag) this.getReceiverPosition()
        }
      })
    },
    markerEvent (e) {
      const key = e.target.offsetParent.getAttribute('key') || e.target.offsetParent.key
      this.clearRMarkerAndPolyLine()
      this.sourceLiveRKey = []
      if (this.infowindowsFlag[key]) {
        this.closeInfoBox(key)
      } else {
        const flag = this.sourceListClickable[key]
        this.selectSId = key
        this.closeInfoBox()
        this.markers[key].openInfoWindow(this.infowindows[key])
        this.infowindowsFlag[key] = true
        if (flag) this.getReceiverPosition()
      }
    },
    // 给marker添加点击事件
    addMarkerEvent (key, imagePath, source, type) {
      let marker = this.markers[key]
      let timeT = source.reportTime ? `${this.$options.filters.DATE(source.reportTime, 'MM/dd/yyyy hh:mm:ss')}` : ''
      let iw = new google.maps.InfoWindow({
        content: `<div class="infoContent" id="${key}" style="height: 250px;width:320px;">
          <img src="${imagePath}" onerror="this.src='${config.myResouceMapIcon.logoImg}'">
          <span class="iconfont deviceIcon">${SETSOURCEICON(source, true)}</span>
          <div class="deviceInfo hasImg">
            <p class="name">${source.name}&nbsp;&nbsp;<span style="color: #999" title="${timeT}">${timeT == '' ? '' : `(${timeT})`}</span></p>
            <p class="peerId" title="${source.type == 'X' ? source.peerId.toUpperCase() : ''}">${source.peerId.toUpperCase()}</p>
          </div>
        </div>`
      })

      let message = new google.maps.InfoWindow({
        content: `<div class="infoContent" style="height: 53px;width:fit-content;width: 320px;">
          <span class="iconfont deviceIcon">${SETSOURCEICON(source, true)}</span>
          <div class="deviceInfo">
            <p class="name">${source.name}&nbsp;&nbsp;<span style="color: #999">${timeT == '' ? '' : `(${timeT})`}</span></p>
            <p class="peerId">${source.peerId.toUpperCase()}</p>
          </div>
        </div>`
      })
      google.maps.event.addListener(marker, 'mouseover', (e) => {
        if (this.infowindowsFlag[key]) {
          message.close(this.mapObj, marker)
          return
        }
        marker = this.markers[key]
        timeT = marker.source.reportTime ? `${this.$options.filters.DATE(marker.source.reportTime, 'MM/dd/yyyy hh:mm:ss')}` : ''
        message = new google.maps.InfoWindow({
          content: `<div class="infoContent" style="height: 53px;width:fit-content;width: 320px;">
          <span class="iconfont deviceIcon">${SETSOURCEICON(marker.source, true)}</span>
          <div class="deviceInfo">
            <p class="name">${marker.source.name}&nbsp;&nbsp;<span style="color: #999">${timeT == '' ? '' : `(${timeT})`}</span></p>
            <p class="peerId">${marker.source.peerId.toUpperCase()}</p>
          </div>
        </div>`
        })
        message.open(this.mapObj, marker)
      })
      google.maps.event.addListener(marker, 'mouseout', (e) => {
        message.close(this.mapObj, marker)
      })
      if (type != 'source') return
      this.infowindowsFlag[key] = false
      // marker icon绑定点击事件,在清空marker点时需要removeListener
      google.maps.event.addListener(marker, 'click', (e) => {
        this.clearRMarkerAndPolyLine()
        this.sourceLiveRKey = []
        if (this.infowindowsFlag[key]) {
          this.closeInfoBox(key)
        } else {
          const flag = this.sourceListClickable[key]
          this.selectSId = key
          message.close(this.mapObj, marker)
          this.closeInfoBox()
          marker = this.markers[key]
          imagePath = SHOWDEVICEIMG(marker.source)
          timeT = marker.source.reportTime ? `${this.$options.filters.DATE(marker.source.reportTime, 'MM/dd/yyyy hh:mm:ss')}` : ''
          iw = new google.maps.InfoWindow({
            content: `<div class="infoContent" id="${key}" style="height: 250px;width:320px;">
          <img src="${imagePath}" onerror="this.src='${config.myResouceMapIcon.logoImg}'">
          <span class="iconfont deviceIcon">${SETSOURCEICON(marker.source, true)}</span>
          <div class="deviceInfo hasImg">
            <p class="name">${marker.source.name}&nbsp;&nbsp;<span style="color: #999" title="${timeT}">${timeT == '' ? '' : `(${timeT})`}</span></p>
            <p class="peerId" title="${marker.source.type == 'X' ? marker.source.peerId.toUpperCase() : ''}">${marker.source.peerId.toUpperCase()}</p>
          </div>
        </div>`
          })
          this.infowindows[key] = iw
          iw.open(this.mapObj, marker)
          this.infowindowsFlag[key] = true
          if (flag) this.getReceiverPosition()
        }
      })
    },
    updateLineOrMarker (key, flag) {
      if (!this.sourceListClickable[key]) {
        this.clearRMarkerAndPolyLine(key)
        if (this.infowindows[key] && this.polylineList[key]) {
          this.closeInfoBox(key)
        }
      }
    },
    /* Get the receiver position by sourceId. */
    getReceiverPosition () {
      this.sourceLiveRKey = []
      this.axios.get('/ccp/tvucc-user/userDevice/getSourceLiveRPosition?sourceId=' + this.selectSId).then(res => {
        if (res.data.errorCode == '0x0' && res.data.errorInfo == 'Success') {
          this.rData = res.data.result
          if (this.rData.length <= 0) {
            this.$message({
              type: 'warning',
              message: this.$t('lang.locationFailed')
            })
            return
          }
          const Sid = this.selectSId
          this.typeFlag = 'receiver'
          if (this.lang == 'zh') {
            this.updateBaiDuMapMarker(this.rData)
          } else {
            this.updateMapMarker(this.rData)
          }
          this.sourceLiveRList[Sid] = this.getValueByKey(this.rData, 'peerId')
          this.sourceLiveRKey = this.getValueByKey(this.rData, 'peerId')
          if (this.lang == 'zh') {
            this.drawLineInBaiDuMap(this.rData)
          } else {
            this.drawLineInMap()
          }
        }
      }).catch(() => {})
    },
    /* Draw the polyline and live receiver markers in the map by one source */
    drawLineInBaiDuMap () {
      const Sid = this.selectSId
      const receiverList = this.sourceLiveRList[Sid]
      // R的livePeerId对应于source的peerId
      const sourceMarker = this.markers[Sid]
      const polylineList = this.polylineList
      if (polylineList[Sid]) {
        var polyLineArr = polylineList[Sid]
        Object.keys(polyLineArr).map(k => {
          polyLineArr[k].setMap(null)
        })
        delete polylineList[Sid]
      }
      polylineList[Sid] = []
      // 获取source的经纬度
      var sourceLatLng = sourceMarker.getPosition()
      receiverList.map(v => {
        // var peerId = v
        var receiverMarker = this.markers[v]
        if (receiverMarker) {
          var receiverLatLng = receiverMarker.getPosition()
          var poly = new BMap.Polyline([sourceLatLng, receiverLatLng], {
            strokeOpacity: 0,
            strokeStyle: 'dashed',
            strokeColor: '#d13c3b'
            // icons: [new BMap.Icon('M 0,-2 0,2',lineSymbol)]
          })
          var path = poly.getPath()
          path.push(sourceLatLng)
          path.push(receiverLatLng)
          this.bMapObj.addOverlay(poly)
          polylineList[Sid].push(poly)
        }
      })
    },
    /* Draw the polyline and live receiver markers in the map by one source */
    drawLineInMap () {
      const Sid = this.selectSId
      const receiverList = this.sourceLiveRList[Sid]
      // R的livePeerId对应于source的peerId
      const sourceMarker = this.markers[Sid]
      const polylineList = this.polylineList
      if (polylineList[Sid]) {
        var polyLineArr = polylineList[Sid]
        Object.keys(polyLineArr).map(k => {
          polyLineArr[k].setMap(null)
        })
        delete polylineList[Sid]
      }
      polylineList[Sid] = []
      // 获取source的经纬度
      var sourceLatLng = sourceMarker.getPosition()
      receiverList.map(v => {
        // var peerId = v
        var receiverMarker = this.markers[v]
        if (receiverMarker) {
          var receiverLatLng = receiverMarker.getPosition()
          var lineSymbol = {
            path: 'M 0,-2 0,2',
            strokeOpacity: 1,
            strokeColor: '#d13c3b',
            scale: 2
          }
          var poly = new google.maps.Polyline({
            strokeOpacity: 0,
            icons: [{
              icon: lineSymbol,
              offset: '0',
              repeat: '18px'
            }]
          })
          var path = poly.getPath()
          path.push(sourceLatLng)
          path.push(receiverLatLng)
          poly.setMap(this.mapObj)
          polylineList[Sid].push(poly)
        }
      })
    },
    /* Clear the polyline and the live receiver markers in the map */
    clearRMarkerAndPolyLine (key) {
      const Sid = key || this.selectSId
      if (!this.sourceLiveRList[Sid]) return
      var polyLineArr = this.polylineList[Sid]
      if (polyLineArr) {
        this.clearPloyLine(polyLineArr)
      }
      const arr = this.sourceLiveRList[Sid]
      Object.keys(arr).map(k => {
        if (this.markers[arr[k]]) this.clearMarker(arr[k])
      })
      this.sourceLiveRList = {}
      this.sourceLiveRKey = []
      this.polylineList = {}
    },
    // clear marker map.removeOverlay(allOverlay[i])
    clearMarker (key, check) {
      if (this.markers[key]) {
        if (this.lang == 'zh') {
          this.bMapObj.removeOverlay(this.markers[key])
        } else {
          this.markers[key].setMap(null)
        }
        delete this.markers[key]
      }
    },
    /* Clear the polyline in the map */
    clearPloyLine (polyLineArr) {
      Object.keys(polyLineArr).map(k => {
        if (this.lang == 'zh') {
          this.bMapObj.removeOverlay(polyLineArr[k])
        } else {
          polyLineArr[k].setMap(null)
        }
      })
    },
    // close infowindow
    closeInfoBox (key) {
      if (key) {
        if (this.infowindows[key]) {
          this.infowindows[key].close()
        }
        this.infowindowsFlag[key] = false
      } else {
        Object.keys(this.infowindowsFlag).map(k => {
          this.closeInfoBox(k)
        })
      }
    },
    getIconUrl (pid, status) {
      let str = ''
      if (status == '1' || status == '3' || status == '21' || status == '23') {
        str = config.myResouceMapIcon.onlineImg
        if (this.typeFlag == 'source') {
          this.sourceListClickable[pid] = false
        }
      }
      if (status == '2' && this.typeFlag == 'receiver' || status == '22' && this.typeFlag == 'receiver') {
        str = config.myResouceMapIcon.receiverImg
        if (this.typeFlag == 'source') {
          this.sourceListClickable[pid] = false
        }
      }
      if (status == '2' && this.typeFlag == 'source' || status == '22' && this.typeFlag == 'source') {
        str = config.myResouceMapIcon.sourceImg
        if (this.typeFlag == 'source') {
          this.sourceListClickable[pid] = true
        }
      }
      if (status == '0' && this.typeFlag == 'source') {
        str = config.myResouceMapIcon.offlineImg
        if (this.typeFlag == 'source') {
          this.sourceListClickable[pid] = false
        }
      }
      return str
    },
    getValueByKey (arr, key) {
      var result = []
      Object.keys(arr).map(k => {
        if (typeof arr[k][key] == 'undefined') {
        } else {
          result.push(arr[k][key])
        }
      })
      return result
    },
    zoomChange (callBack) {
      var map = this.mapObj
      this.zoomDefault(map.zoom)
      google.maps.event.addListener(map, 'zoom_changed', () => {
        this.zoomDefault(map.zoom)
        if (map.zoom < 2) {
          map.setZoom(2)
          return
        }
        if (map.zoom > 22) {
          map.setZoom(22)
        }
      })
      if (typeof callBack == 'function') {
        callBack(map)
      }
    },
    zoomDefault (nowZoom) {
      let outScaleImg, zoomObj, zoomInObj, zoomOutObj
      const L = window.localStorage.getItem('mapLang')
      zoomOutObj = L.includes('zh') ? $('.gm-control-active').eq(2) : $("button[title='Zoom out']")
      zoomInObj = L.includes('zh') ? $('.gm-control-active').eq(1) : $("button[title='Zoom in']")
      // zoom in +
      // zoom out -
      if (nowZoom < 10) {
        zoomObj = zoomOutObj
        outScaleImg = config.myResouceMapIcon.notScale
      } else {
        zoomObj = zoomInObj
        outScaleImg = config.myResouceMapIcon.notInScale
      }
      if (nowZoom <= 2 || nowZoom >= 22) {
        zoomObj.addClass('disClick')
        zoomObj.find('img').eq(0).attr('src', outScaleImg)
        zoomObj.find('img').eq(1).attr('src', outScaleImg)
        zoomObj.find('img').eq(2).attr('src', outScaleImg)
      } else {
        if (zoomObj.hasClass('disClick')) {
          zoomObj.removeClass('disClick')
          zoomOutObj.find('img').eq(0).attr('src', 'data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Cpath%20fill%3D%22%23666%22%20d%3D%22M0%2C7h18v4H0V7z%22%2F%3E%0A%3C%2Fsvg%3E%0A')
          zoomOutObj.find('img').eq(1).attr('src', 'data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Cpath%20fill%3D%22%23333%22%20d%3D%22M0%2C7h18v4H0V7z%22%2F%3E%0A%3C%2Fsvg%3E%0A')
          zoomOutObj.find('img').eq(2).attr('src', 'data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Cpath%20fill%3D%22%23111%22%20d%3D%22M0%2C7h18v4H0V7z%22%2F%3E%0A%3C%2Fsvg%3E%0A')
          zoomInObj.find('img').eq(0).attr('src', 'data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2218%2C7%2011%2C7%2011%2C0%207%2C0%207%2C7%200%2C7%200%2C11%207%2C11%207%2C18%2011%2C18%2011%2C11%2018%2C11%22%2F%3E%0A%3C%2Fsvg%3E%0A')
          zoomInObj.find('img').eq(1).attr('src', 'data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Cpolygon%20fill%3D%22%23333%22%20points%3D%2218%2C7%2011%2C7%2011%2C0%207%2C0%207%2C7%200%2C7%200%2C11%207%2C11%207%2C18%2011%2C18%2011%2C11%2018%2C11%22%2F%3E%0A%3C%2Fsvg%3E%0A')
          zoomInObj.find('img').eq(2).attr('src', 'data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Cpolygon%20fill%3D%22%23111%22%20points%3D%2218%2C7%2011%2C7%2011%2C0%207%2C0%207%2C7%200%2C7%200%2C11%207%2C11%207%2C18%2011%2C18%2011%2C11%2018%2C11%22%2F%3E%0A%3C%2Fsvg%3E%0A')
        }
      }
    }
  },
  // 路由离开之前先关闭定时器
  beforeRouteLeave (to, from, next) {
    if (this.timeOut) clearInterval(this.timeOut)
    if (this.initBaiduTimers) clearInterval(this.initBaiduTimers)
    if (this.initTimers) clearInterval(this.initTimers)
    next()
  }
}
</script>
<style lang="less" scoped>
#mapContent {
  height: calc(100% - 47px);
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>
